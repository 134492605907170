// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-cancel-js": () => import("./../../../src/pages/cancel.js" /* webpackChunkName: "component---src-pages-cancel-js" */),
  "component---src-pages-documentation-backend-js": () => import("./../../../src/pages/documentation-backend.js" /* webpackChunkName: "component---src-pages-documentation-backend-js" */),
  "component---src-pages-documentation-mobile-device-js": () => import("./../../../src/pages/documentation-mobile-device.js" /* webpackChunkName: "component---src-pages-documentation-mobile-device-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-no-payment-js": () => import("./../../../src/pages/no-payment.js" /* webpackChunkName: "component---src-pages-no-payment-js" */),
  "component---src-pages-payroll-js": () => import("./../../../src/pages/payroll.js" /* webpackChunkName: "component---src-pages-payroll-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-termsandconditions-js": () => import("./../../../src/pages/termsandconditions.js" /* webpackChunkName: "component---src-pages-termsandconditions-js" */),
  "component---src-pages-time-tracking-js": () => import("./../../../src/pages/time-tracking.js" /* webpackChunkName: "component---src-pages-time-tracking-js" */)
}

